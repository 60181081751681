import { ClientType } from '../../Contact/Contact';
import { PrismicSlice } from '../../Prismic/Prismic';

export enum PrismicClientType {
    both = 'Both',
    gymVisitors = 'Gym visitors',
    gymOwners = 'Gym owners',
}

export interface PrismicContactForm {
    contact_form_title?: string;
    contact_form_description?: string;
    contact_form_type?: PrismicClientType;
}

export type PrismicContactFormSlice = PrismicSlice<PrismicContactForm>;

export interface ContactForm {
    type?: ClientType;
    title: string;
    description: string;
}
