import { FC, ReactElement, useRef } from 'react';

import { Wrapper } from '../../components';
import { ClientType, ContactFormData } from '../../entities/Contact/Contact';
import usePrefersReducedMotion from '../../hooks/usePrefersReducedMotion';
import useElementScrollRatio from '../../hooks/useSectionScrollPosition';
import { ContactForm } from '..';
import { ContactSectionBackground } from './subcomponents';

import './ContactSection.scss';

interface ContactSectionProps {
    formIsLoading: boolean;
    formIsSuccessful: boolean;
    type?: ClientType;
    title: string;
    description: string;
    onSubmit: (formData: ContactFormData) => void;
    className?: string;
}

const ContactSection: FC<ContactSectionProps> = ({
    formIsLoading,
    formIsSuccessful,
    type,
    title,
    description,
    onSubmit,
    className = '',
}): ReactElement => {
    const sectionRef = useRef<HTMLDivElement>(null);
    const scrollRatio = useElementScrollRatio(sectionRef);
    const prefersReducedMotion = usePrefersReducedMotion();

    return (
        <div ref={sectionRef} className={`contact-section ${className}`}>
            <Wrapper className="contact-section__description-and-contact-wrapper">
                <div className="contact-section__description-wrapper">
                    <h2 className="contact-section__title">
                        {title}
                    </h2>

                    <p className="contact-section__description">
                        {description}
                    </p>
                </div>

                <ContactForm
                    isLoading={formIsLoading}
                    isSuccessful={formIsSuccessful}
                    fixedType={type}
                    onSubmit={onSubmit}
                    className="contact-section__form"
                />
            </Wrapper>

            <div className="contact-section__background-wrapper">
                <div className="contact-section__background">
                    <ContactSectionBackground
                        isAnimationEnabled={scrollRatio > 0 && !prefersReducedMotion}
                        className="contact-section__svg"
                    />
                </div>
            </div>
        </div>
    );
};

export default ContactSection;
