import { ClientType } from '../../Contact/Contact';
import { ContactForm, PrismicClientType, PrismicContactForm } from './ContactForm';

const transformToContactFormType = (prismicContactFormType?: PrismicClientType): ClientType | undefined => {
    if (prismicContactFormType === PrismicClientType.gymVisitors) {
        return ClientType.visitor;
    }

    if (prismicContactFormType === PrismicClientType.gymOwners) {
        return ClientType.owner;
    }

    return undefined;
};

export const transformToContactForm = (prismicContactForm: PrismicContactForm): ContactForm => {
    const type = transformToContactFormType(prismicContactForm.contact_form_type);

    return {
        type,
        title: prismicContactForm.contact_form_title || '',
        description: prismicContactForm.contact_form_description || '',
    };
};
